import React from "react";
import { brandLogoPath } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { brandLogo } from "../../../../assets/brand";
import {
  checkFileIfExist,
  fileLabels,
} from "../../../../services/globalFunctions";

export const ExceptTmpOrJsMainContact = ({ companyData }) => {
  const haveContact =
    companyData.companies.length && companyData.companies[0].contacts.length;
  return (
    <div
      className="company-profile-box box"
      style={{ paddingTop: 15, marginBottom: 0 }}
    >
      <div className="company-contact">
        <p className="subsection text-uppercase">Main contact</p>
        <div className="row">
          <div className="col-lg-6">
            <img
              style={{
                marginRight: "20px",
                maxWidth: "130px",
              }}
              src={
                checkFileIfExist(
                  companyData.companies[0].files,
                  fileLabels.company.mainContact
                ) || brandLogo
              }
              alt="..."
            />
          </div>
          <div className="company-contact-list col-lg-6">
            <p className="company-contact-list-name">
              <span
                className={
                  haveContact && companyData.companies[0].contacts[0].title
                    ? ""
                    : "d-none"
                }
              >
                {haveContact ? companyData.companies[0].contacts[0].title : ""}
              </span>
              {haveContact
                ? companyData.companies[0].contacts[0].first_name
                : ""}{" "}
              {haveContact
                ? companyData.companies[0].contacts[0].last_name
                : ""}
            </p>
            <p
              className={
                "font-weight-light " +
                (haveContact &&
                (companyData.companies[0].contacts[0].job_title ||
                  companyData.companies[0].contacts[0].position)
                  ? ""
                  : "d-none")
              }
            >
              {haveContact
                ? companyData.companies[0].contacts[0].job_title ||
                  companyData.companies[0].contacts[0].position
                : ""}
            </p>
            <p
              className={
                "company-contact-list-phone " +
                (haveContact && companyData.companies[0].contacts[0].phone
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
              {haveContact ? companyData.companies[0].contacts[0].phone : ""}
            </p>
            <p
              className={
                "company-contact-list-phone " +
                (haveContact && companyData.companies[0].contacts[0].mobile
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />{" "}
              {haveContact ? companyData.companies[0].contacts[0].mobile : ""}
            </p>
            <p
              className={
                "company-contact-list-email d-inline-flex " +
                (haveContact && companyData.companies[0].contacts[0].email
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <a
                onClick={() =>
                  (window.location.href = `mailto:${
                    haveContact
                      ? companyData.companies[0].contacts[0].email
                      : ""
                  }`)
                }
              >
                {haveContact && companyData.companies[0].contacts[0].email && (
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2 mt-1" />
                )}

                <span style={{ wordBreak: "break-word" }}>
                  {haveContact
                    ? companyData.companies[0].contacts[0].email
                    : ""}
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
