import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../../services/const";
import { Button } from "react-bootstrap";
import {
  checkFileIfExist,
  fileLabels,
  userLevelColors,
} from "../../services/globalFunctions";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faFile } from "@fortawesome/free-solid-svg-icons";
import { FooterCard } from "../../components/FooterCard";
import { activeProduct } from "../../store/actions/breadcrumbsAction";
import { Link } from "react-router-dom";
import { brandLogoPath, checkIsJumpseatOrTmp } from "./utils";
import { TmpOrJsMainContact } from "./components/MainContact/TmpOrJsMainContact";
import { ExceptTmpOrJsMainContact } from "./components/MainContact/ExceptTmpOrJsMainContact";
import { ReadyToRequest } from "./components/ReadyToRequest";
import { RequestOrMainContact } from "./components/RequestOrMainContact";
import { isResponseError } from "../../utils/axios";
import { brandLogo } from "../../assets/brand";
import { filePathToGlobal } from "../../utils/file";
import { AgentInfoModalFromCustomer } from "./components/AgentInfoModalFromCustomer";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slickPlay: true,
  arrows: true,
};

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      data: null,
      isLoading: true,
      companyData: {},
      icon: "",
      agentProfileModal: false,
      contact_email: "",
    };
    this.getProduct = this.getProduct.bind(this);
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.getData = this.getData.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getProduct();
    this.setState({ isLoading: false });
  }

  getCompanyDetails(id) {
    return new Promise((resolve, reject) => {
      const { data } = this.state;
      axios
        .get(`/companies/${id || data.company_id}`)
        .then(async (ret) => {
          if (!isResponseError(ret)) {
            this.setState({
              companyData: ret.data,
              icon: userLevelColors(ret.data.user_level || "economy").icon,
            });
          }
          resolve();
        })
        .catch((err) => resolve());
    });
  }

  getProduct() {
    return new Promise((resolve, reject) => {
      const { id } = this.state;
      axios
        .get(`/commodities/${id}`, {
          params: {
            with: [
              "user",
              "user.companies",
              "user.files",
              "user.role",
              "user.inviter",
            ],
          },
        })
        .then(async (ret) => {
          if (!isResponseError(ret)) {
            const companyData = ret.data.user;
            let contact_email = "";

            if (checkIsJumpseatOrTmp(companyData)) {
              contact_email = companyData.inviter.email;
            } else {
              contact_email = companyData.email;
            }

            this.setState({
              data: ret.data,
              companyData: companyData,
              icon: userLevelColors(ret.data.user.user_level.code || "economy")
                .icon,
              contact_email: contact_email || "info@worldring.org",
            });
            this.props.activeProduct(ret.data.name);

            // await this.getCompanyDetails(ret.data.company_id);
          }
          resolve();
        })
        .catch((err) => resolve());
    });
  }

  render() {
    const { isLoading, data, companyData, icon, contact_email } = this.state;
    const { level } = this.props;

    console.log("contact_email", contact_email);

    return (
      <div className="page-container AdminPageContainer ProductDetailsPage CompanyProfile">
        <div className="page-header"></div>
        {!isLoading && data ? (
          <div className="container mt-5 pt-4">
            <div className="row">
              <div className="col-lg-4">
                <div className="slide-container mb-5">
                  {data.files.length ? (
                    <Slider {...settings}>
                      {data.files.map((img, i) => {
                        return (
                          <div className="each-slide" key={i}>
                            <div
                              className="image-container page-header"
                              style={{ background: "#fff" }}
                            >
                              <img
                                style={{
                                  height: "100%",
                                  width: "auto",
                                  objectFit: "contian",
                                }}
                                className="lazy"
                                src={filePathToGlobal(img).path}
                                alt={img.file_name}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <img
                        style={{
                          maxHeight: "264px",
                          margin: "0 auto",
                        }}
                        className="lazy"
                        src={brandLogo}
                        alt={""}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="box">
                  <div className="d-flex pl-4 pt-4">
                    <div className="col-lg-8">
                      <div className="d-flex">
                        <h4>{data.name}</h4>
                        {data.certification ? (
                          <div
                            className="d-flex align-items-center px-3"
                            style={{ gap: "10px" }}
                          >
                            <FontAwesomeIcon size="2x" icon={faAward} />{" "}
                            Certified
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <h6
                        style={{
                          width: "fit-content",
                          color: "white",
                          background: "black",
                        }}
                        className="p-2"
                      >
                        Listing #: {data.product.id}
                      </h6>
                      {data.certification_description && (
                        <p>{data.certification_description}</p>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <p
                        className="p-2 text-uppercase"
                        style={{
                          background: "gray",
                          color: userLevelColors(companyData.user_level)
                            .buttonBg,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {data.commodity_type.code}
                      </p>
                      <div>
                        <p className="font-weight-bold mb-0">Origin</p>
                        <p
                          style={{
                            maxWidth: "120px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {data.country || "Global"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-4 d-flex">
                    <div className="col-lg-6">
                      <div>
                        <div className="d-flex justify-content-between py-3">
                          <p className="m-0 font-weight-bold">HS Code</p>
                          <p className="m-0">{data.product.code}</p>
                        </div>
                        {checkIsJumpseatOrTmp(companyData) ? (
                          !!data.incoterm && (
                            <div className="d-flex justify-content-between py-3">
                              <p className="m-0 font-weight-bold">Incoterm</p>
                              <p className="m-0">{data.incoterm}</p>
                            </div>
                          )
                        ) : (
                          <div className="d-flex justify-content-between py-3">
                            <p className="m-0 font-weight-bold">Incoterm</p>
                            <p className="m-0">{data.incoterm || ""}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="py-3 d-flex justify-content-center">
                        <Button
                          style={{
                            background: userLevelColors(level).buttonBg,
                            color: userLevelColors(level).buttonFont,
                            borderColor: userLevelColors(level).buttonFont,
                          }}
                          onClick={() =>
                            (window.location.href = `mailto:${contact_email}`)
                          }
                          className="mr-4 px-2"
                          variant="secondary"
                        >
                          {data.commodity_type.code === "search"
                            ? "Contact Buyer"
                            : "Contact Seller"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8">
                <h4 className="mb-4">Product Description</h4>
                <div className="p-3 box">
                  <p>{data.description}</p>
                  {data.seasonality_description && (
                    <p className="mt-5">
                      <strong>Seasonality description: </strong>
                      {data.seasonality_description}
                    </p>
                  )}
                  {data.mode_of_production && (
                    <p className="mt-5">
                      <strong>Mode of production: </strong>
                      {data.mode_of_production}
                    </p>
                  )}
                </div>
                {data.target_group && (
                  <>
                    <h4 className="mb-4 mt-4">Target Group</h4>
                    <div className="p-3 box">
                      <p>{data.target_group || "No info"}</p>
                    </div>
                  </>
                )}

                {data.specification && (
                  <>
                    <h4 className="mb-4 mt-4">Specification</h4>
                    <div className="p-3 box">
                      <p>{data.specification || "No info"}</p>
                    </div>
                  </>
                )}
                <FooterCard />
              </div>

              <div className="col-lg-4">
                <h4 className="mb-4 px-3">
                  {checkIsJumpseatOrTmp(companyData)
                    ? "Request Contact Details"
                    : data.commodity_type.code === "search"
                    ? "Buyer Profile"
                    : "Seller profile"}
                </h4>
                <div className="col-lg-12 company-profile">
                  <div
                    className="box"
                    style={{
                      background: userLevelColors(companyData.user_level.code)
                        .bg,
                    }}
                  >
                    <span
                      className="py-2 d-flex align-items-center justify-content-center text-uppercase font-weight-light w-100 text-right"
                      style={{ fontSize: "18px" }}
                    >
                      <img
                        height={50}
                        src={
                          icon ? require(`../../assets/userIcons/${icon}`) : ""
                        }
                        alt={"..."}
                      />
                      <span
                        className="pl-2"
                        style={{
                          color: userLevelColors(companyData.user_level.code)
                            .font,
                        }}
                      >
                        {userLevelColors(companyData.user_level.code).label ===
                        "Temp"
                          ? "Jumpseat"
                          : userLevelColors(companyData.user_level.code).label}
                      </span>
                    </span>
                  </div>
                  {checkIsJumpseatOrTmp(companyData) ? (
                    <div className="company-profile-image-box box">
                      <div className="company-image-box">
                        <img
                          src={
                            companyData.inviter.companies[0].wp_logo ||
                            brandLogo
                          }
                          className="company-profile-image"
                          alt="..."
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="company-profile-image-box box">
                      <div className="company-image-box">
                        <Link to={`/company-profile/${companyData.id}`}>
                          <img
                            src={
                              checkFileIfExist(
                                companyData.companies[0].files,
                                fileLabels.company.logo
                              ) || brandLogo
                            }
                            className="company-profile-image"
                            alt="..."
                          />
                        </Link>
                      </div>
                    </div>
                  )}
                  {checkIsJumpseatOrTmp(companyData) ? (
                    <></>
                  ) : (
                    <div
                      className="company-profile-box box"
                      style={{ marginTop: 30 }}
                    >
                      <div className="company-credentials">
                        <h3 className="company-profile-title">
                          {companyData.companies[0].name}
                        </h3>
                        <p className="company-profile-location">
                          {companyData.companies[0].addresses[0].city_name},{" "}
                          {companyData.companies[0].addresses[0].country_name}
                        </p>
                        <hr className="seperator" />
                        <p className="subsection text-uppercase">Headquarter</p>
                        <div>
                          <p className="company-address-list">
                            {companyData.companies[0].addresses[0].street},{" "}
                            {companyData.companies[0].addresses[0].postal_code}
                          </p>
                          <p className="company-address-list">
                            {companyData.companies[0].addresses[0].city_name},{" "}
                            {companyData.companies[0].addresses[0].country_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <RequestOrMainContact companyData={companyData} />
                  {(companyData.user_level.code === "economy" ||
                    companyData.user_level.code === "first_class" ||
                    companyData.user_level.code === "business") && (
                    <div
                      className="company-profile-box box"
                      style={{ paddingTop: "15px", marginTop: 30 }}
                      onClick={() => this.setState({ agentProfileModal: true })}
                    >
                      <div className="company-contact">
                        <p className="subsection text-uppercase">
                          AGENT IN CHARGE
                        </p>
                        <div className="row">
                          <div className="col-lg-12 d-flex imgCenter">
                            {checkIsJumpseatOrTmp(companyData) ? (
                              <img
                                style={{
                                  width: "100%",
                                  maxHeight: "150px",
                                  objectFit: "contain",
                                }}
                                src={
                                  companyData.inviter.companies[0].wp_logo ||
                                  brandLogo
                                }
                                alt="..."
                              />
                            ) : (
                              <img
                                style={{
                                  width: "100%",
                                  maxHeight: "150px",
                                  objectFit: "contain",
                                }}
                                src={
                                  companyData.inviter.companies[0].wp_logo ||
                                  brandLogo
                                }
                                alt="..."
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {checkFileIfExist(data.files, fileLabels.product.pdf) && (
                    <div className="box" style={{ marginTop: 30 }}>
                      <div
                        className="p-5 text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let pdfWindow = window.open("");
                          pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                              encodeURI(
                                checkFileIfExist(
                                  data.files,
                                  fileLabels.product.pdf
                                )
                              ) +
                              "'></iframe>"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} size="3x" />
                        <p className="mt-3">
                          {
                            data.files.find(
                              (f) => f.label === fileLabels.product.pdf
                            )[0].file_name
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container mt-5 text-center">
            <div
              style={{
                height: "3em",
                width: "3em",
                color: userLevelColors(level).bg,
              }}
              className={"spinner-border "}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {this.state.agentProfileModal && (
          <AgentInfoModalFromCustomer
            agentInfo={this.state.companyData}
            closeModal={() => this.setState({ agentProfileModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  activeProduct: (data) => dispatch(activeProduct(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
